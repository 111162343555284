import React, { useEffect, useState } from "react";

const Footer = () => {
  const [montName, setMonthName] = useState("");
  useEffect(() => {
    let date = new Date();
    let month = date.toLocaleDateString("defaut", { month: "long" });
    let year = date.getFullYear();
    setMonthName(month[0].toUpperCase() + month.slice(1) + " " + year);
  }, []);

  return (
    <div
      id="footer"
      className="flex justify-between px-14 py-5 border-t-2 mb-14 md:mb-0 border-gray-300 bg-white"
    >
      <p className="text-gray-400">Made with 💚 by Takyon</p>
      <p className="text-gray-400">{montName}</p>
    </div>
  );
};

export default Footer;

import GroupCollectionList from "./GroupCollectionList";
import { getGroupOverviewData, iGroup, verifyGroup } from "@/services/groups";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { LucideSiren } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useRbac } from "@/hooks/rbac";

import { iCollection } from "@/services/collection";
import { useEffect, useMemo, useState } from "react";
import OverviewGroup from "@/pages/collection/analytics/OverviewGroup";
import { baseResponse } from "@/services/dataanalysismask";
export default function GroupSection({
  group,
  collections,
  onRefresh,
}: {
  group: iGroup;
  collections: iCollection[];
  onRefresh(): void;
}) {
  const { t } = useTranslation();

  const { isSuperAdmin, can } = useRbac();

  const verify = async () => {
    await verifyGroup({ groupId: group._id });
    onRefresh();
  };

  const [groupOverview, setGroupOverview] = useState<baseResponse | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGroupOverview = async () => {
      try {
        const data = await getGroupOverviewData(group._id);
        setGroupOverview(data);
      } catch (error) {
        console.error("Failed to fetch group overview data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupOverview();
  }, [group._id]);

  return (
    <>
      <div className="p-4 md:gap-8 md:px-14 md:py-8 bg-black">
        {groupOverview && (
          <div className="w-full">
            <p className="text-2xl font-medium text-white">{group.name}</p>
            {/* <OverviewGroup
              data={groupOverview as any}
              group={group}
              onRefresh={onRefresh}
              isLoading={loading}
            /> */}
          </div>
        )}

        {!group.verified && isSuperAdmin ? (
          <Alert className="mt-5">
            <LucideSiren className="h-4 w-4" color="red" />
            <AlertTitle>{t("group.not_verified_title")}</AlertTitle>
            <AlertDescription>
              <div className="flex">
                <div>
                  {t("group.not_verified")}{" "}
                  <a href={t("group.not_verified_1")} target="_blank">
                    {t("group.not_verified_1")}
                  </a>
                </div>
                {isSuperAdmin && (
                  <Button
                    onClick={() => {
                      verify();
                    }}
                    className="ml-auto px-2"
                    size="sm"
                  >
                    {t("group.verify")}
                  </Button>
                )}
              </div>
            </AlertDescription>
          </Alert>
        ) : null}
      </div>
      <div className="p-4 md:gap-8 md:px-14 md:py-8">
        <GroupCollectionList
          group={group}
          collections={collections}
          onRefresh={onRefresh}
        />
      </div>
    </>
  );
}

import {
  COLLECTION_PERMISSIONS,
  iCollection,
  onBoardingCompleted,
} from "@/services/collection";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDefaultLink, LINK_TYPE } from "@/lib/links";
import { datetimeToString } from "@/utils/generic";
import { useRbac } from "@/hooks/rbac";
import TooltipButton from "../buttons/TooltipButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FileJson2, MoreVertical, Settings, Users } from "lucide-react";
import { useModal } from "@/context/ModalContext";
import CollectionTeam from "../collection/CollectionTeam";
import CollectionSettingsModal from "../collection/CollectionSettingsModal";
import { iGroup } from "@/services/groups";
import { Badge } from "../ui/badge";

export default function GroupCollectionList({
  group,
  collections,
  onRefresh,
}: {
  group: iGroup;
  collections: iCollection[];
  onRefresh?: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSuperAdmin, can } = useRbac();
  const { showModal, hideModal } = useModal();

  return (
    <>
      {collections ? (
        <div className="grid grid-cols-12 gap-4">
          {collections?.map((collection: iCollection) => {
            const canInvite = can(COLLECTION_PERMISSIONS.memebers, collection);
            // const onboarding = onBoardingCompleted(collection);
            const onboarding = true;
            const defaultLink = collection.dataAnalysisMaskId
              ? LINK_TYPE.COLLECTION_ANALYTICS
              : LINK_TYPE.COLLECTION_TAKS;

            return (
              <Card
                className="shrink-0 col-span-4 flex flex-col justify-between"
                key={`gc_${collection._id}`}
              >
                <CardHeader className="px-5 pt-3 pb-5">
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <span className="text-xl font-medium	overflow-hidden text-ellipsis mr-1">
                        {collection.name}
                      </span>
                    </div>
                    {(isSuperAdmin || canInvite) && (
                      <div className="ml-auto flex items-start">
                        {isSuperAdmin && (
                          <Badge
                            variant={collection.isFree ? "outline" : "takyon"}
                            className={collection.isFree ? "text-takyon" : ""}
                          >
                            {collection.isFree
                              ? t("collection.isFree")
                              : t("collection.pro")}
                            {collection.scriptEnabled && (
                              <FileJson2 size="16" />
                            )}
                          </Badge>
                        )}
                        <DropdownMenu>
                          <DropdownMenuTrigger
                            asChild
                            className="cursor-pointer"
                          >
                            <MoreVertical />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {(isSuperAdmin || canInvite) && (
                              <DropdownMenuItem
                                className="flex items-center gap-2"
                                onClick={() =>
                                  showModal(
                                    <CollectionTeam
                                      collection={collection}
                                      onRefresh={() => {
                                        hideModal();
                                        onRefresh?.();
                                      }}
                                      group={group}
                                    />,
                                    {
                                      title: t("collection.members"),
                                      description: "",
                                    }
                                  )
                                }
                              >
                                <Users
                                  size={18}
                                  className="text-gray-400"
                                ></Users>
                                Team
                              </DropdownMenuItem>
                            )}
                            {isSuperAdmin && (
                              <DropdownMenuItem
                                className="flex items-center gap-2"
                                onClick={() =>
                                  showModal(
                                    <CollectionSettingsModal
                                      onRefresh={() => {
                                        hideModal();
                                        onRefresh?.();
                                      }}
                                      collection={collection as iCollection}
                                      group={group}
                                    />,
                                    {
                                      title: t("collection.settings"),
                                      description: "",
                                    }
                                  )
                                }
                              >
                                <Settings
                                  size={18}
                                  className="text-gray-400"
                                ></Settings>
                                {t("group.settings")}
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <div>
                  <CardContent className="px-5 pb-3">
                    {collection.nftCount !== undefined &&
                      collection.nftCount > 0 && (
                        <div className="mb-6">
                          <span className="text-5xl font-medium">
                            {collection.nftCount}
                          </span>{" "}
                          {t("collection.analytics.generatedtak_title")}
                        </div>
                      )}
                    <dl className="grid gap-1 text-sm">
                      {/* <div className="flex items-center justify-between">
                    <dt className="text-muted-foreground text-nowrap">
                      {t("collection.value_generated")}
                    </dt>
                    <dd className="text-nowrap">
                      {collection.valueGenerated
                        ? `€ ${collection.valueGenerated.toFixed(0)}`
                        : "-"}
                    </dd>
                  </div> */}
                      <div className="flex items-center justify-between">
                        <dt className="text-muted-foreground">
                          {t("collection.status")}
                        </dt>
                        <Badge
                          variant={collection.isActive ? "takyon" : "outline"}
                        >
                          {collection.isActive
                            ? t("collection.active")
                            : t("collection.not_active")}
                        </Badge>
                      </div>
                      {/* <div className="flex items-center justify-between">
                      <dt className="text-muted-foreground">
                        {t("collection.latest_tak_date")}
                      </dt>
                      <dd className="">
                        {collection.lastNftCreatedAt ? (
                          <>
                            {datetimeToString(
                              collection.lastNftCreatedAt,
                              undefined,
                              false
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </dd>
                    </div> */}
                    </dl>
                  </CardContent>
                  <CardFooter className="px-5 flex gap-2 flex-col">
                    {onboarding !== true && (
                      <TooltipButton
                        variant="outline"
                        className="w-full"
                        disabled={
                          !can(COLLECTION_PERMISSIONS.update, collection)
                        }
                        tooltipMessage={t(
                          "tooltips.collection_missing_permission"
                        )}
                        onClick={() =>
                          navigate(
                            `/collection/${collection._id}/infos/details`
                          )
                        }
                      >
                        {t("collection.onboarding")}
                      </TooltipButton>
                    )}
                    {(onboarding == true || isSuperAdmin) && (
                      <Button
                        variant={"takyon"}
                        className="w-full"
                        onClick={() => {
                          const link = getDefaultLink(collection);
                          const encodedLink = encodeURIComponent(link);
                          document.cookie = `lastVisitedCollection=${encodedLink}; path=/; max-age=86400`;
                          navigate(link);
                        }}
                      >
                        {t("collection.see_details")}
                      </Button>
                    )}
                  </CardFooter>
                </div>
              </Card>
            );
          })}
        </div>
      ) : (
        <div>{t("collection.no_data")}</div>
      )}
    </>
  );
}
